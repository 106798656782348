import { getUnauthorization } from "../../util/api/User";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    getUnauthorization()
        .then(() => {
            localStorage.clear();
            navigate("/", { replace: true });
        })
        .catch((err) => {
            localStorage.clear();
            navigate("/", { replace: true });
        });
    return (
        <div className="">
            <div className="container">
                <div className="row row-custom-vh justify-content-center align-items-center">
                    <div className="col-12 col-lg-4"></div>
                </div>
            </div>
        </div>
    );
};
export default Logout;
