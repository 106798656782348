import { apiClient } from "./ApiConfig";
import { getToken } from "../../storage/Token";

export async function getAuthorization(login, password) {
    const path = "/session/login";
    return apiClient.post(
        path,
        {
            name: login,
            password: password,
            group: 279,
        }
    );
}

export async function getUnauthorization() {
    const path = "/session/logout";
    return apiClient.post(
        path,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        }
    );
}
