import "./Result.scss";

const Result = ({ winner, setWinner }) => {
    return (
        <div className={"result-container result-container-position overflow-hidden border border-white border-opacity-50 rounded-8 p-ad16"}>
            <div className="d-flex align-items-center justify-content-between mb-ad12">
                <div className="fw-700 lh-20 fs-14 text-black">Победители</div>
                <div className={"fw-700 lh-20 fs-14 text-black text-end" + (winner.length === 0 ? " d-none" : "")}>
                    <button
                        className="border-0 bg-transparent text-black-50 pe-0"
                        onClick={() => {
                            setWinner([]);
                        }}
                    >Очистить</button>
                </div>
            </div>

            <div className="result-container-list sp-scrollbar-y sp-scrollbar-y-white">
                {/* @TODO: один элемент победителей */}
                {winner.map((item) => (
                    <div className="d-flex align-items-center mt-ad12" key={item.id}>
                        <img src={item.avatar} alt="" className="avatar-32 rounded-circle bg-white me-ad8" />
                        <div>
                            <p className="fs-14 fl-14 fw-400 text-black text-truncate-1 mb-0">{item.name}</p>
                            {(item.comment) ? <p className="fs-14 fl-14 fw-400 text-muted small text-truncate-1 mb-0">{item.comment}</p> : <></>}
                        </div>
                    </div>
                ))}
                {
                    (winner.length ===0) ? <div className="text-black-50 small">Счастливчик не определен</div>     : ""
                }
            </div>
        </div>
    );
};
export default Result;
