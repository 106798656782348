import { useState } from "react";
import { getAuthorization } from "../../util/api/User";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../storage/Token";
import { setUser } from "../../storage/User";

const ErrorMessage = <div className="alert alert-danger small text-muted">Имя пользоваятеля указано неверно, введен неверный пароль, или Ваш пользователь не имеет доступа к специальным режимам розыгрыша.</div>;

const InfoMessage = (
    <div className="alert alert-info">
        <p className="small text-muted mb-0">Вход осуществляется по логину и паролю основного сайта 24-ok.ru при условии нахождения в специальной группе - имеющей доступ к розыгрышу.</p>
    </div>
);

const Login = () => {
    document.title = "Вход";

    const navigate = useNavigate();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getAuthorization(login, password)
            .then((res) => {
                setToken(res.data.id);
                setUser(res.data.user);
                navigate("/");
            })
            .catch((err) => {
                setError(true);
                console.log("Ошибка обновлении данных", err);
            });
    };

    return (
        <div className="">
            <div className="container">
                <div className="row row-custom-vh justify-content-center align-items-center">
                    <div className="col-12 col-lg-4">
                        <div className="border rounded-4 px-4 px-lg-4 py-4 py-lg-4 bg-white">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <form onSubmit={handleSubmit}>
                                        {error ? ErrorMessage : InfoMessage}

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Имя
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="username"
                                                aria-describedby="emailHelp"
                                                onChange={(e) => {
                                                    setLogin(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">
                                                Пароль
                                            </label>
                                            <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} />
                                        </div>

                                        <button type="submit" className="btn btn-green text-white w-100">
                                            Войти
                                        </button>
                                        <a href="/" type="submit" className="btn btn-outline-green w-100 mt-2">
                                            Вернутся
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
