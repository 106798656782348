import Result from "./components/Result";
import Setting from "./components/Setting";
import Logotype from "../../img/logotype.svg";
import { useState } from "react";

import DrawProcedure from "../../util/DrawProcedure";

const Draw = () => {
    const [drawSetting, setDrawSetting] = useState({ type: "num", winCount: 1, numRange: [0, 100], numList: "Участник 1\nУчастник 2\nУчастник 3\nУчастник 4\nУчастник 5\nУчастник 6\nУчастник 7\nУчастник 8\nУчастник 9\nУчастник 10\nУчастник 11\nУчастник 12\nУчастник 13\nУчастник 14\nУчастник 15\nУчастник 16\nУчастник 17\nУчастник 18\nУчастник 19\nУчастник 20", reDraw: false, selectPrize: null });
    const [drawValue, setDrawValue] = useState("Узнаем победителя?!");
    const [drawProcess, setDrawProcess] = useState(false);
    const [winner, setWinner] = useState([]);
    
    const drawClass = () => {
        if (drawSetting.type == "limoniya") {
            return "search-participant-input fs-24 lh-24 fs-lg-80 lh-lg-80 fw-900 text-green text-center text-truncate-1 px-ad24 py-ad24 px-lg-ad48 py-lg-ad16 mx-auto";
        } else {
            return "search-participant-input fs-24 lh-24 fs-lg-80 lh-lg-80 fw-900 text-white text-center text-truncate-1 px-ad24 py-ad24 px-lg-ad48 py-lg-ad16 mx-auto";
        }
    }

    return (
        <div className="">
            <div className="container">
                <div className="row row-custom-vh">
                    <div className="col-12 d-flex flex-column align-items-center justify-content-end">
                        <img src={Logotype} alt="" className="img-fluid logo-h" />
                        <div className="mt-ad48 mt-lg-ad60 w-100">
                            <input type="text" readOnly className={drawClass()} id="FormControlInputResult1" value={drawValue} />
                        </div>
                        <button className="btn btn-white-purple-link rounded-8 fs-20 lh-32 px-ad48 py-ad24 mt-ad48 mt-lg-ad60" onClick={() => {DrawProcedure(setDrawValue, setDrawProcess, drawSetting, [], setWinner, 1)}} disabled={drawProcess}>Узнать победителя</button>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-self-end mt-ad24 mt-md-0 mb-md-ad24">
                        <Setting drawSetting={drawSetting} setDrawSetting={setDrawSetting} disabled={drawProcess} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-ad48 mt-md-0 mb-ad24 mb-md-0">
                        <Result winner={winner} setWinner={setWinner} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Draw;
