import "./Setting.scss";
import { Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../storage/User";
import { getPrize } from "../../../util/api/Action";

const defaultTheme = [
    { id: 1, class: "bg-img-var-1", name: "Конфетти" },
    { id: 2, class: "bg-img-var-2", name: "Подарки" },
    { id: 3, class: "bg-img-var-3", name: "Лента" },
    //{ id: 4, class: "bg-img-var-4", name: "Подарки" },
    { id: 5, class: "bg-img-var-5", name: "Лимония" },
];

const Setting = ({ drawSetting, setDrawSetting, disabled }) => {
    const navigate = useNavigate();
    const [customClass, setCustomClass] = useState(1);
    const [customPicture, setCustomPicture] = useState("");
    const [prizeList, setPrizeList] = useState({});

    const clearTheme = (body) => {
        body.removeAttribute("style");
        defaultTheme.forEach((element) => {
            body.classList.remove(element.class);
        });
    };

    const updateBackgroundClass = (val) => {
        setCustomClass(val);
        setCustomPicture("");
        let body = document.querySelector("body");
        clearTheme(body);
        body.classList.add("bg-img-var-" + val);
    };

    const updateBackgroundImage = (val) => {
        setCustomClass(0);
        setCustomPicture(val);
        let body = document.querySelector("body");
        clearTheme(body);
        body.setAttribute("style", `background-image: url(${val})`);
    };

    const LoginLogout = () => {
        let user = getUser();
        if (user) {
            return (
                <a href="/logout" type="button" className="btn btn-link text-body px-2">
                    <i className="fa-light fa-right-from-bracket fa-fw"></i>
                </a>
            );
        } else {
            return (
                <a href="/login" type="button" className="btn btn-link text-body px-2">
                    <i className="fa-light fa-right-to-bracket fa-fw"></i>
                </a>
            );
        }
    };

    const updatePrizeList = () => {
        getPrize()
            .then((res) => {
                setPrizeList(res.data);
            })
            .catch((err) => {
                console.log("Ошибка обновлении данных", err);
            });
    };

    const setSpecialDraw = () => {
        setDrawSetting({ ...drawSetting, type: "limoniya", reDraw: false, selectPrize:1586791769 });
        updateBackgroundClass(5);
        updatePrizeList();
    };

    const SpecialDraw = () => {
        let user = getUser();
        if (user) {
            return (
                <button className={"btn btn-link d-flex flex-fill align-items-center justify-content-center user-edit-tabs-btn fs-14 lh-20 text-decoration-none cursor-pointer px-ad16 py-ad8 " + (drawSetting.type === "limoniya" ? "active" : "")} onClick={setSpecialDraw}>
                    Лимония
                </button>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div className="">
            <button className="btn btn-white-black-link rounded-8 px-ad24 py-ad12" type="button" id="button-setting-modal" data-bs-toggle="modal" data-bs-target="#setting_modal" disabled={disabled}>
                Настройки
            </button>
            <div className="modal fade" tabIndex="-1" id="setting_modal">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-500">
                    <div className="modal-content">
                        <div className="modal-header border-bottom border-dark-12 ps-ad24 py-ad16 pe-ad16">
                            <h5 className="modal-title fs-20 lh-24 fw-500">Настройка</h5>
                            <div>
                                <LoginLogout />
                                <button type="button" className="btn btn-link text-body pe-0" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="fa-solid fa-xmark fa-fw fa-xl"></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body sp-scrollbar-y sp-scrollbar-y-dark p-ad24">
                            <div className="">
                                <label htmlFor="modalNavTabs" className="form-label fs-14 lh-20 text-dark-60">
                                    Тип розыгрыша
                                </label>
                                <div className="d-flex justify-content-between user-edit-tabs-pills fs-14 lh-20 bg-dark-12 rounded-8 p-ad2">
                                    <button
                                        className={"btn btn-link d-flex flex-fill align-items-center justify-content-center user-edit-tabs-btn fs-14 lh-20 text-decoration-none cursor-pointer px-ad16 py-ad8 " + (drawSetting.type === "num" ? "active" : "")}
                                        onClick={() => {
                                            setDrawSetting({ ...drawSetting, type: "num" });
                                        }}
                                    >
                                        Число
                                    </button>
                                    <button
                                        className={"btn btn-link d-flex flex-fill align-items-center justify-content-center user-edit-tabs-btn fs-14 lh-20 text-decoration-none cursor-pointer px-ad16 py-ad8 " + (drawSetting.type === "list" ? "active" : "")}
                                        onClick={() => {
                                            setDrawSetting({ ...drawSetting, type: "list" });
                                        }}
                                    >
                                        Список
                                    </button>
                                    <SpecialDraw />
                                </div>
                            </div>

                            {/* @TODO: Показываем при активной вкладке "Число" type === "num" */}
                            {drawSetting.type === "num" ? (
                                <div className="mt-ad24">
                                    <div className="row">
                                        <div className="col-6 pe-ad8">
                                            <label htmlFor="inputMin" className="form-label fs-14 lh-20 text-dark-60">
                                                От
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control form-setting-style bg-dark-12 border border-dark-12 text-dark-60 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                                id="inputMin"
                                                placeholder=""
                                                value={drawSetting.numRange[0]}
                                                onChange={(e) => {
                                                    setDrawSetting({ ...drawSetting, numRange: [parseInt(e.target.value), drawSetting.numRange[1]] });
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-ad8">
                                            <label htmlFor="inputMax" className="form-label fs-14 lh-20 text-dark-60">
                                                До
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control form-setting-style bg-dark-12 border border-dark-12 text-dark-60 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                                id="inputMax"
                                                placeholder=""
                                                value={drawSetting.numRange[1]}
                                                onChange={(e) => {
                                                    setDrawSetting({ ...drawSetting, numRange: [drawSetting.numRange[0], parseInt(e.target.value)] });
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Slider
                                                min={0}
                                                max={1000}
                                                value={drawSetting.numRange}
                                                onChange={(e) => {
                                                    setDrawSetting({ ...drawSetting, numRange: e.target.value });
                                                }}
                                                sx={{
                                                    color: "#990099",
                                                    "& .MuiSlider-rail": {
                                                        height: "2px",
                                                        color: "#D6D6D6",
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {drawSetting.type === "list" ? (
                                <div className="mt-ad24">
                                    <label htmlFor="customList1" className="form-label fs-14 lh-20 text-dark-60">
                                        Список &mdash; {drawSetting.numList.split(/\r\n|\r|\n/).length}
                                    </label>
                                    <textarea
                                        className="form-control sp-scrollbar-y sp-scrollbar-y-dark modal-setting-list form-setting-style rounded-8 border border-dark-12 bg-dark-12 px-ad16 py-ad12"
                                        placeholder="1 строка - 1 участник"
                                        id="customList1"
                                        value={drawSetting.numList}
                                        onChange={(e) => {
                                            setDrawSetting({ ...drawSetting, numList: e.target.value });
                                        }}
                                    ></textarea>
                                </div>
                            ) : (
                                <></>
                            )}

                            {prizeList.length > 0 && drawSetting.type === "limoniya" ? (
                                <div className="mt-ad24">
                                    <label className="form-label fs-14 lh-20 text-dark-60" htmlFor="inputStyleBg">
                                        Оформление
                                    </label>
                                    <select
                                        className="form-select form-select-setting bg-dark-12 border border-dark-12 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                        id="inputStyleBg"
                                        onChange={(e) => {
                                            setDrawSetting({ ...drawSetting, selectPrize: e.target.value });
                                        }}
                                        value={drawSetting.selectPrize}
                                    >
                                        {prizeList.map((prize) => (
                                            <option value={prize.id} key={prize.id}>
                                                {prize.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="mt-ad24">
                                <label htmlFor="customRange1" className="form-label fs-14 lh-20 text-dark-60">
                                    Победителей
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-setting-style bg-dark-12 border border-dark-12 text-dark-60 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                    id="customRange1"
                                    value={drawSetting.winCount}
                                    onChange={(e) => {
                                        setDrawSetting({ ...drawSetting, winCount: e.target.value });
                                    }}
                                />
                                <Slider
                                    min={1}
                                    max={50}
                                    value={drawSetting.winCount}
                                    onChange={(e) => {
                                        setDrawSetting({ ...drawSetting, winCount: e.target.value });
                                    }}
                                    sx={{
                                        color: "#990099",
                                        "& .MuiSlider-rail": {
                                            height: "2px",
                                            color: "#D6D6D6",
                                        },
                                    }}
                                />
                            </div>
                            {drawSetting.type === "num" || drawSetting.type === "list" ? (
                                <>
                                    <div className="mt-ad24">
                                        <div className="form-check">
                                            <input
                                                id="reDraw"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={drawSetting.reDraw}
                                                onChange={(e) => {
                                                    setDrawSetting({ ...drawSetting, reDraw: e.target.checked });
                                                }}
                                            />
                                            <label className="form-check-label ps-ad12" htmlFor="reDraw">
                                                Возможность повторного выигрыша
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-ad24">
                                        <label className="form-label fs-14 lh-20 text-dark-60" htmlFor="inputStyleBg">
                                            Оформление
                                        </label>
                                        <select
                                            className="form-select form-select-setting bg-dark-12 border border-dark-12 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                            id="inputStyleBg"
                                            onChange={(e) => {
                                                updateBackgroundClass(e.target.value);
                                            }}
                                            value={customClass}
                                        >
                                            <option value="0" disabled>
                                                Своё оформление
                                            </option>
                                            {defaultTheme.map((theme) => (
                                                <option value={theme.id} key={theme.id}>
                                                    {theme.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mt-ad24">
                                        <label htmlFor="FormControlInputOwnBg" className="form-label fs-14 lh-20 text-dark-60">
                                            Своё фоновое изображение
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-setting-style bg-dark-12 border border-dark-12 text-dark-60 fs-16 lh-24 rounded-8 px-ad16 py-ad12"
                                            id="FormControlInputOwnBg"
                                            placeholder="Ссылка на изображение jpeg или png"
                                            value={customPicture}
                                            onChange={(e) => {
                                                updateBackgroundImage(e.target.value);
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className="d-grid gap-2 mt-ad24">
                                <button className="btn btn-purple-link rounded-8 transition-03 px-ad24 py-ad16" data-bs-dismiss="modal">
                                    Готово
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Setting;
