import { apiClient } from "./ApiConfig";
import { getToken } from "../../storage/Token";

export async function getPrize() {
    const path = "/action/prize";
    return apiClient.get(path, {
        headers: {
            Authorization: "Bearer " + getToken(),
        },
    });
}

export async function getVariants() {
    const path = "/action/variants";
    return apiClient.get(path, {
        headers: {
            Authorization: "Bearer " + getToken(),
        },
    });
}

export async function getWinner(id, prize) {
    const path = "/action/winner";
    return apiClient.get(path, {
        params: {
            'id': id,
            'prize': prize,
        },
        headers: {
            Authorization: "Bearer " + getToken(),
        },
    });
}
