import Confeti from "./Confeti";
import { getVariants, getWinner } from "./api/Action";

let timer, timer2;
let variantsForSpinner = [];
let countVariants = 10;
let interval = 1000;
let interval2 = 1000;

const avatar = ["/img/avatar_1.png", "/img/avatar_2.png", "/img/avatar_3.png", "/img/avatar_4.png", "/img/avatar_5.png", "/img/avatar_6.png", "/img/avatar_7.png", "/img/avatar_8.png"];

const checkRedraw = (drawSetting, winner, variant) => {
    //Возможен повторный выигрыш
    if (drawSetting.reDraw) {
        return false;
    }
    //Уже побеждал
    if (winner.find((element) => element.name === variant)) {
        return true;
    }
    return false;
};

const Draw = (variantsForSpinner, setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n) => {
    //Запускаем розыгрыш
    timer = setInterval(() => {
        let nowVariants = variantsForSpinner[0];
        if (nowVariants.user) {
            setDrawValue(nowVariants.user.name);
        } else {
            setDrawValue(nowVariants);
        }
        variantsForSpinner.splice(0, 1);
        if (variantsForSpinner.length === 0) {
            if (drawSetting.type === "limoniya" && nowVariants.user) {
                console.log(drawSetting.selectPrize);
                getWinner(nowVariants.id, drawSetting.selectPrize);
                setWinner((w) => [{ id: w.length, time: Date.now(), name: nowVariants.user.name, comment: nowVariants.num, avatar: nowVariants.user.avatar[60] }, ...w]);
                winner = [{ id: winner.length, time: Date.now(), name: nowVariants.user.name, comment: nowVariants.num, avatar: nowVariants.user.avatar[60] }, ...winner];
            } else {
                setWinner((w) => [{ id: w.length, time: Date.now(), name: nowVariants, avatar: avatar[getRandomArbitrary(0, 7)] }, ...w]);
                winner = [{ id: winner.length, time: Date.now(), name: nowVariants, avatar: avatar[getRandomArbitrary(0, 7)] }, ...winner];
            }
            clearInterval(timer);
            Confeti();
            if (n < drawSetting.winCount) {
                setTimeout(() => {
                    DrawProcedure(setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n + 1);
                }, interval2);
            } else {
                setDrawProcess(false);
            }
            return;
        }
    }, interval / variantsForSpinner.length);
};

const DrawProcedure = (setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n) => {
    setDrawProcess(true);
    let variant;

    variantsForSpinner = [];
    //Выбираем набор для промотки из чисел
    if (drawSetting.type === "num") {
        console.log("Spin - " + n);
        while (variantsForSpinner.length < Math.min(countVariants, drawSetting.numRange[1] - drawSetting.numRange[0])) {
            variant = getRandomArbitrary(drawSetting.numRange[0], drawSetting.numRange[1]);
            console.log("Variant - " + variant);
            console.log("VariantsForSpinner - " + variantsForSpinner);
            if (!variantsForSpinner.includes(variant) && !checkRedraw(drawSetting, winner, variant)) {
                variantsForSpinner.push(variant);
            }
        }
        Draw(variantsForSpinner, setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n);
    }
    //Выбираем набор для промотки из списка
    if (drawSetting.type === "list") {
        let numList = drawSetting.numList.split(/\r\n|\r|\n/);
        while (variantsForSpinner.length < Math.min(countVariants, numList.length)) {
            variant = numList[getRandomArbitrary(0, numList.length)];
            if (!variantsForSpinner.includes(variant) && variant !== undefined) {
                variantsForSpinner.push(variant);
            }
        }
        Draw(variantsForSpinner, setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n);
    }

    //Выбираем набор для специального розыгрыша
    if (drawSetting.type === "limoniya") {
        getVariants()
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    variantsForSpinner = res.data;
                    Draw(variantsForSpinner, setDrawValue, setDrawProcess, drawSetting, winner, setWinner, n);
                }
            })
            .catch((err) => {
                console.log("Ошибка обновлении данных", err);
            });
    }
};

function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

export default DrawProcedure;
