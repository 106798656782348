import axios from "axios";
import { getToken } from "../../storage/Token";

const baseUrl = () => {
    //const host = window.location.host;
    const url = "https://api.24-ok.ru/v2";
    return url;
};

export const apiClient = axios.create({
    baseURL: baseUrl(),
});
